<!-- 心理咨询配置 -- 咨询周期设置 -- 新增 -->
<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消新增?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <div class="form-body" style="padding-top: 35rem;padding-left: 60rem">
      <h4>咨询基础配置</h4>
      <br>
      <el-form ref="form" :model="formData" label-width="100rem" :rules="rules">
        <el-form-item label="咨询名称" prop="term_name">
          <el-input placeholder="请输入咨询名称" maxlength="50" v-model="formData.term_name"></el-input>
        </el-form-item>
        <el-form-item label="选择校区" prop="school_id">
          <el-select :popper-append-to-body="false" v-model="formData.school_id" placeholder="请选择校区">
            <el-option v-for="item in schoolConfig" :key="item.id" :label="item.school_name" :value="item.id">
          </el-option></el-select>
        </el-form-item>
        <el-form-item label="咨询周期" prop="time">
          <el-date-picker v-model="formData.time" type="daterange" @change="timeChange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    
  
</edit-template>
</template>

<script>
import moment from "moment";

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      loading: false,

      // 校区配置
      schoolConfig: [],

      // 表单数据
      formData: {
        term_name: '',
        school_id: '',
        time: ''
      },
      // 表单验证规则
      rules: {
        term_name: [{required: true, message: '请输入名称', trigger: 'change'}],
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        time: [{required: true, message: '请选择咨询周期', trigger: 'change'}],
      }
    }
  },
  created() {
    this.getSchoolConfig()
  },
  methods: {
    getSchoolConfig() {
      this.$_axios.get('site/school').then(res => {
        let data = res.data.data
        this.schoolConfig = data
      })
    },

     timeChange(e){
      console.log(e)
    },

    // 表单提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          let params = {
            term_name: this.formData.term_name,
            school_id: this.formData.school_id,
            start_at: this.formData.time[0],
            end_at: this.formData.time[1],
          }
          this.$_axios2.post('api/evaluation/counseling-setting/term', {...params}).then(res => {
            if (res.data.status === 0) {
              this.$message.success({
                message: '新增成功',
                onClose: () => {
                  this.$store.commit("setPage", 1);
                  this.$router.go(-1)
                }
              })
            }
          }).finally(() => {
            this.loading = false;
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form .el-input {
  width: 250rem !important;
}
</style>
